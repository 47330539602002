// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
/**
 * Check element in vewport
 */
let transformProperty = window.innerWidth < 1024 ? 0 : -10;
document.documentElement.style.setProperty(
  '--image-transform-property',
  `${transformProperty}%`
);
let mobDesctop = window.innerWidth < 1024 ? true : false;
let darkCircleTransformProperty = mobDesctop ? '40%' : '-15%';
let scaleDashedCircle = mobDesctop ? '1.2' : '1.38';
document.documentElement.style.setProperty(
  '--scale-dashed-circle',
  `${scaleDashedCircle}`
);
document.documentElement.style.setProperty(
  '--dark-circle',
  `${darkCircleTransformProperty}`
);

function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
function showElTimeOut(idx, el) {
  for (let i = 0; i <= idx; i++) {
    if (i === idx) {
      $(el).show();
    }
  }
}
function isOnScreen(elem, isHeroMobile = false) {
  let $elem = $(elem);
  let $window = $(window);

  let docViewTop = $window.scrollTop();
  let docViewBottom = docViewTop + $window.height();

  let elemTop = $elem.offset().top;
  let elHeight = $elem.height();
  let elemBottom = elemTop + elHeight;

  if (window.innerWidth < 1024) {
    if (isHeroMobile) {
      return elemTop >= docViewTop;
    } else {
      return elemTop <= docViewTop + 300;
    }
  } else {
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
}
function textAnimateHero(arr) {
  arr.each(function (idx, el) {
    if (idx == 0) {
      if (isOnScreen($(el), true)) {
        $(el)
          .addClass('text-transform-animate')
          .css('transform', 'translateX(0)');
      }
    }
  });
}
function imageAnimateHero(arr) {
  arr.each(function (idx, el) {
    if (idx == 0) {
      if (isOnScreen($(el), true)) {
        if (el.hasAttribute('data-image')) {
          $(el)
            .addClass('image-animate')
            .css('transform', `translate(${transformProperty}, 0)`);
        } else {
          $(el)
            .addClass('image-animate-color')
            .css('transform', 'translateY(0)');
        }
      }
    }
  });
}
function animateTextFunc(arr) {
  arr.each(function (idx, el) {
    if (isOnScreen($(el))) {
      $(el)
        .addClass('text-transform-animate')
        .css('transform', 'translateX(0)');
    }
  });
}
function animateImageFunc(arr) {
  arr.each(function (idx, el) {
    if (isOnScreen($(el))) {
      if (el.hasAttribute('data-image')) {
        $(el)
          .addClass('image-animate test')
          .css('transform', `translate(${transformProperty}%, 0%)`);
        // .css('transform', `translate(89%, 0%)`);
      } else
        $(el).addClass('image-animate-color').css('transform', 'translateY(0)');
    }
  });
}
function animateCircleHero(arr) {
  arr.each(function (idx, el) {
    if (idx == 0) {
      if ($(el)[0].classList.contains('circles--blue')) {
        if (isOnScreen($(el), true)) {
          $(el)
            .find('.big-circle')
            .addClass('circle-transform--big-blue')
            .css('transform', 'translate(0, -50%) scale(1)');
          $(el)
            .find('.small-circle')
            .addClass('circle-transform--small-blue')
            .css('transform', 'translateY(0) scale(1)');
        }
      }

      if ($(el)[0].classList.contains('circles--white')) {
        if (isOnScreen($(el), true)) {
          $(el)
            .find('.big-circle')
            .addClass('circle-transform--big-white')
            .css(
              'transform',
              `translate(-50%, -50%) scale(${scaleDashedCircle})`
            );
          $(el)
            .find('.small-circle')
            .addClass('circle-transform--small-white')
            .css('transform', 'translate(-70%, 30%) scale(0.6)');
        }
      }

      if ($(el)[0].classList.contains('circles--dark')) {
        if (isOnScreen($(el), true)) {
          $(el)
            .find('.big-circle')
            .addClass('circle-transform--big-dark')
            .css(
              'transform',
              `translate(-51%, ${darkCircleTransformProperty}) scale(1)`
            );
        }
      }
    }
  });
}
function animateCircleFunc(arr) {
  arr.each(function (idx, el) {
    if ($(el)[0].classList.contains('circles--blue')) {
      if (isOnScreen($(el))) {
        $(el)
          .find('.big-circle')
          .addClass('circle-transform--big-blue')
          .css('transform', 'translate(0, -50%) scale(1)');
        $(el)
          .find('.small-circle')
          .addClass('circle-transform--small-blue')
          .css('transform', 'translateY(0) scale(1)');
      }
    }

    if ($(el)[0].classList.contains('circles--white')) {
      if (isOnScreen($(el))) {
        $(el)
          .find('.big-circle')
          .addClass('circle-transform--big-white')
          .css(
            'transform',
            `translate(-50%, -50%) scale(${scaleDashedCircle})`
          );
        $(el)
          .find('.small-circle')
          .addClass('circle-transform--small-white')
          .css('transform', 'translate(-70%, 30%) scale(0.6)');
      }
    }

    if ($(el)[0].classList.contains('circles--dark')) {
      if (isOnScreen($(el))) {
        $(el)
          .find('.big-circle')
          .addClass('circle-transform--big-dark')
          .css(
            'transform',
            `translate(-51%, ${darkCircleTransformProperty}) scale(1)`
          );
      }
    }
  });
}
let animateText = $('div[data-text-animate]');
let animateImage = $('.text_image_animation__image-wrapper');
let circlesWrapper = $('.text_image_animation__circles');
$(document).on('ready', function () {
  if ($('body').hasClass('home')) {
    if (getCookie('anchor')) {
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $(getCookie('anchor')).offset().top - 60,
          },
          1500
        );
      eraseCookie('anchor');
    }
  }
  textAnimateHero(animateText);
  imageAnimateHero(animateImage);
  animateCircleHero(circlesWrapper);

  if (window.innerWidth < 1024) {
    $('.header-menu')
      .find('li')
      .each(function (idx, el) {
        $(el).hide();
        setTimeout(showElTimeOut(idx, el), 400);
      });
  }

  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    let anchor = $(this).attr('href');
    setCookie('anchor', anchor, 1);
    if (!$('body').hasClass('home')) {
      document.location.href = `/`;
    }
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $(anchor).offset().top - 60,
        },
        1500
      );
  });

  if (document.location)
    /**
     * Make elements equal height
     */
    $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  let burgerBtn = $('.burger-svg'),
    closeMenuSvg = $('.burger-close-svg');
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active test');
      closeMenuSvg.toggle();
      burgerBtn.toggle();
      $('.menu-mobile-header-menu-container').slideToggle('slow');
      $('.top-bar').css('display', 'block');
      // $('.menu-header-menu-container').removeClass('container-animate-hide');
      // $('.menu-header-menu-container').addClass('container-animate-show');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      // $('.menu-header-menu-container').removeClass('container-animate-show');
      // $('.menu-header-menu-container').addClass('container-animate-hide');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 *
 * Scripts which runs after all elements load
 */
let headerHeight = $('.header').outerHeight(false);
if ($('body').hasClass('admin-bar')) {
  $('body').css({
    paddingTop: headerHeight + 'px',
    marginTop: 0,
  });
  if (window.innerWidth < 768) {
    $('.header').css('marginTop', '46px');
  } else {
    $('.header').css('marginTop', '32px');
  }
} else {
  $('body').css('paddingTop', headerHeight + 'px');
}
$(window).on('load', function () {
  // jQuery code goes here
  if ($('body').hasClass('admin-bar')) {
    $('body').css({
      paddingTop: headerHeight + 'px',
      marginTop: '0 !important',
    });
    if (window.innerWidth < 768) {
      $('.header').css('marginTop', '46px');
    } else {
      $('.header').css('marginTop', '32px');
    }
  } else {
    $('body').css('paddingTop', headerHeight + 'px');
  }

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  if ($(window).width() < 1024) {
    $('.footer__copy').find('.grid-container').addClass('full');
  } else {
    $('.footer__copy').find('.grid-container').removeClass('full');
  }
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */

$(window).on('scroll', function () {
  // jQuery code goes here
  animateTextFunc(animateText);
  animateImageFunc(animateImage);
  animateCircleFunc(circlesWrapper);
});
